import * as React from 'react';

import { graphql, useStaticQuery } from "gatsby";

import Layout from '../components/Layout/layout';
import { Seo } from '../components/Seo/seo';

import CardsSection from '../components/CardsSection';
import ContactForm from '../components/ContactForm';
import PageHero from '../components/PageHero';
import TextBlockSection from '../components/TextBlockSection';

const BenefitsPage = () => {
    const data = useStaticQuery(graphql`
    {
      allContentfulPageLayout(filter: {id: {eq: "303b06ca-7a1d-55f6-ba96-17b9c68eb822"}} ) {
        edges {
          node {
            name
            title
            content {
              ... on ContentfulPageHero {
                id
                name
                bannerImage {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: BLURRED
                    quality: 100
                    width: 1440
                  )
                }
                bannerText {
                  raw
                }
                smallBannerImage {
                  gatsbyImageData(
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    width: 950
                    quality: 100
                  )
                }
              }
              ... on ContentfulTextBlock {
                id
                name
                title {
                  raw
                }
                body {
                  body
                }
                colorScheme
              }
              ... on ContentfulCardsSection {
                id
                name
                cardTitle {
                  raw
                }
                cards {
                  id  
                  name
                  title
                  cardIcon {
                    gatsbyImageData(
                      placeholder: BLURRED
                      layout: FIXED 
                      width: 75
                      height: 75
                      )
                  }
                  cardDescription {
                    raw
                  }
                  cardFootNote {
                    cardFootNote
                  }
                }
              }
            }
            contactForm
          }
        }
      }
      allContentfulLocation {
        edges {
          node {
            phoneNumber
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageHero data={data.allContentfulPageLayout.edges[0].node.content[0]} />
      <TextBlockSection data={data.allContentfulPageLayout.edges[0].node.content[1]}/>
      <CardsSection data={data.allContentfulPageLayout.edges[0].node.content[2]} />
      <ContactForm data={data.allContentfulLocation.edges[0].node.phoneNumber} />
    </Layout>
  )
}

export const Head = () => (
  <Seo 
    title="The benefits of The Good Life"
    description="Travel as a member of the TGL family, and you will have the benefits you need to start a new chapter in your career."
    pathname="/benefits"

  />
)

export default BenefitsPage